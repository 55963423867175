import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-hot-toast";

function InviteModal(props) {
    const [show, setShow] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("teacher");

    const { getAccessTokenSilently } = useAuth0();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleInvite = () => {
        setIsPosting(true);

        let formdata = new FormData();

        if (email === "") {
            toast.error("You need to write an email!");
            return;
        }
        formdata.append("email", email);
        formdata.append("role", role);
        formdata.append("org", props.team_id);

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:teacher_profile",
        }).then((token) => {
            fetch(process.env.REACT_APP_MM_API_URL + "organizations/invites", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formdata,
            })
                .then((response) => {
                    console.log(response);
                    return response.json();
                })
                .then((data) => {
                    if (data === "Already invited") {
                        toast.error(
                            "This person has already been invited to this team."
                        );
                    } else {
                        toast.success("Invite successfully sent");
                        setShow(false);
                    }
                    setIsPosting(false);
                    props.refresh();
                })
                .catch((error) => {
                    toast.error("Something went wrong while creating invites");
                    console.log(error);
                    setIsPosting(false);
                });
        });
    };

    return (
        <>
            <Button className="btn-sm" variant="success" onClick={handleShow}>
                Invite Users{" "}
            </Button>

            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Invite user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                placeholder="teacher@school.com"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>Role</Form.Label>
                            <Form.Select
                                onChange={(e) => setRole(e.target.value)}
                                aria-label="Select a role for the invited user"
                            >
                                <option value="teacher">Teacher</option>
                                <option value="admin">Administrator</option>
                            </Form.Select>
                            {role === "admin" && (
                                <Form.Text className="text-danger">
                                    Administrators have access to all management
                                    and features of this team including billing,
                                    invites, and more. Make sure that you only
                                    set the Admin role for users who need these
                                    permissions.
                                </Form.Text>
                            )}
                        </Form.Group>
                    </Row>
                    {isPosting ? (
                        <Button className="" variant="success" disabled>
                            Sending invite...
                        </Button>
                    ) : (
                        <Button
                            className=""
                            variant="success"
                            onClick={handleInvite}
                        >
                            Send Invite
                        </Button>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default InviteModal;
