import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";


export function NoSubscription(props) {

    return (
        <>
            <Row className="mt-5">
                <Col>
                    <h2>Current Plan</h2>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button
                        to="https://gomegaminds.com/schools-districts"
                        as={Link}
                        className="ms-2 d-flex align-items-center"
                        variant="success"
                    >
                        Learn more
                    </Button>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Card>
                        <Card.Header className="fw-bold">Basic</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <p>Included</p>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            3 Lessons
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            3 Student Projects
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            25 users per room
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col>
                                    <p>Not Included</p>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            Unlimited Lessons
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Unlimited Student Projects
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Access to all our EdTech
                                            Integrations
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Clever / SSO Support
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Additional Content Packages
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Bespoke activities for your school /
                                            district
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            AI-driven Speech-To-Speech avatars
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
