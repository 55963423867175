import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import moment from "moment";


export function HasSubscription(props) {
    const { getAccessTokenSilently } = useAuth0();

    if (props.subscription === "Permanent Premium") {
        return (
            <Alert variant="success" className="mt-2 mb-5">
                <h2>Subscription managed by MegaMinds</h2>
            </Alert>
        );
    }

    return (
        <>
            <Row>
                <Col>
                    <p>Error loading subscription</p>
                </Col>
            </Row>
        </>
    );
}
