import React, { useState } from "react";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import moment from "moment";
import "moment-timezone";

import InviteModal from "./modals/InviteModal";
import ManageUsersModal from "./modals/ManageUsersModal";
import RoomPermissionsModal from "./modals/RoomPermissions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { getPicture } from "../../utils/get-picture";

export default function OrganizationCard({ organization, invites, update }) {
    const [showRoomPermissionModal, setShowRoomPermissionModal] =
        useState(false);

    if (organization.role === "teacher") {
        return (
            <Card as="li" className="mb-5">
                <Card.Body>
                    <Row>
                        <Col
                            xs={1}
                            className="d-flex justify-content-between align-items-center pt-3 pb-4"
                        >
                            {organization.logo ? (
                                <img
                                    src={getPicture(organization.logo)}
                                    className="img-fluid"
                                    alt="logo school"
                                />
                            ) : (
                                <FontAwesomeIcon icon={faSitemap} fixedWidth />
                            )}
                        </Col>
                        <Col
                            xs={5}
                            className="d-flex justify-content-between align-items-center pt-3 pb-4"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    <span className="ms-3">
                                        {organization.name}
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {organization.members.map((member) => (
                                        <tr key={member.id}>
                                            <td>{member.user.first_name}</td>
                                            <td>
                                                <span className="badge bg-purple-dark">
                                                    {member.role.name}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }

    const pendingInvites =
        typeof organization.invites !== "string" &&
        organization.invites?.filter(
            (invite) => invite.accepted === false && invite.rejected !== true
        );

    const seats = organization.stripe_quantity
        ? organization.stripe_quantity
        : 0;

    return (
        <>
            <Card as="li" className="mb-5">
                <Card.Body>
                    <Row>
                        <Col
                            xs={1}
                            className="d-flex justify-content-between align-items-center pt-3 pb-4"
                        >
                            {organization.logo ? (
                                <img
                                    src={getPicture(organization.logo)}
                                    className="img-fluid"
                                    alt="logo school"
                                />
                            ) : (
                                <FontAwesomeIcon icon={faSitemap} fixedWidth />
                            )}
                        </Col>
                        <Col
                            xs={5}
                            className="d-flex justify-content-between align-items-center pt-3 pb-4"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    <span className="ms-3">
                                        {organization.name}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col
                            xs={6}
                            className="d-flex justify-content-end align-items-center"
                        >
                            <ManageUsersModal
                                update={update}
                                organization={organization}
                            />
                            <RoomPermissionsModal
                                update={update}
                                show={showRoomPermissionModal}
                                setShow={setShowRoomPermissionModal}
                                organization={organization}
                            />
                            <Button
                                onClick={() => setShowRoomPermissionModal(true)}
                                className="btn-sm me-3"
                                variant="outline-secondary"
                            >
                                Disable Features
                            </Button>
                            <InviteModal
                                refresh={update}
                                team_id={organization.id}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-4">
                            <span className="badge bg-secondary">
                                {organization.members.length +
                                    pendingInvites.length}{" "}
                                / {seats} members
                            </span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Last Active</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {organization.members.map((member) => (
                                        <tr key={member.id}>
                                            <td>{member.user.first_name}</td>
                                            <td>{member.user.email}</td>
                                            <td>
                                                <span className="badge bg-purple-dark">
                                                    {member.role.name}
                                                </span>
                                            </td>
                                            <td>
                                                {moment(
                                                    member.user.last_login
                                                ).format(
                                                    "dddd, MMMM Do, YYYY HH:ss"
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    {pendingInvites?.length > 0 &&
                                        pendingInvites?.map((seat) => (
                                            <tr className={seat.id}>
                                                <td></td>
                                                <td></td>
                                                <td>{seat.email}</td>
                                                <td>
                                                    <span className="badge bg-primary">
                                                        Invite Pending
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
}
